import { SelectOption } from "@msys/ui";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  SelectProps as MuiSelectProps,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { Field, FieldConfig, useField } from "formik";
import { Select } from "formik-mui";
import { noop } from "lodash";
import React from "react";

export type { SelectOption };

const EmptyIcon = () => <></>;

export interface SelectFieldProps<Value, Multiple extends boolean = false>
  extends Omit<MuiSelectProps<Value>, "value" | "renderValue" | "multiple">,
    Pick<FieldConfig, "validate"> {
  multiple?: Multiple;
  renderValue?: (
    value: Multiple extends true ? Value[] : Value
  ) => React.ReactNode;
  name: string;
  options?: SelectOption<Value>[];
  clearable?: boolean;
  onClear?: () => void;
}

export const SelectField = <Value, Multiple extends boolean = false>({
  fullWidth,
  multiple,
  name,
  options = [],
  clearable = false,
  onClear,
  ...props
}: SelectFieldProps<Value, Multiple>) => {
  const { t } = useTranslate("Global");
  const [, { value, error }, { setValue }] = useField<string>(name);

  return (
    <Field
      {...props}
      value={multiple ? value || [] : value}
      component={Select}
      name={name}
      fullWidth={fullWidth}
      formControl={{
        fullWidth,
        sx: {
          ...(props.size === "extra-small" && {
            "& .MuiInputLabel-root": { display: "none !important" },
          }),
        },
      }}
      multiple={multiple}
      onClose={multiple ? noop : undefined} // this is needed due to a bug on formik-mui https://github.com/stackworx/formik-mui/issues/308
      inputProps={{
        name,
        id: name,
      }}
      IconComponent={clearable && value ? EmptyIcon : undefined}
      endAdornment={
        clearable && value ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              color="primary"
              onClick={
                onClear ??
                (() => {
                  setValue("");
                })
              }
              aria-label="Clear"
              style={{ position: "relative", top: 8 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
      //makes options appear under text field
      MenuProps={
        multiple
          ? {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }
          : undefined
      }
      sx={{
        "& .MuiInputBase-inputAdornedEnd": {
          paddingRight: "0 !important",
        },
      }}
      // error handling for extra-small size
      {...(props.size === "extra-small" && error
        ? {
            startAdornment: (
              <Tooltip title={error}>
                <ErrorIcon fontSize="small" color="error" />
              </Tooltip>
            ),
            error: true,
            helperText: null,
          }
        : undefined)}
    >
      {options.map(option => (
        // @ts-ignore MenuItem only allows number | string | readonly string[] https://github.com/mui-org/material-ui/issues/14286
        <MenuItem
          key={option.key ?? JSON.stringify(option.value)}
          value={option.value}
          style={{ minHeight: "36px" }}
        >
          {option.icon ? (
            <Stack alignItems="center" direction="row" spacing={1}>
              {option.icon}
              <div>{option.label}</div>
            </Stack>
          ) : (
            option.label
          )}
        </MenuItem>
      ))}
      {!options || !options.length ? (
        <MenuItem disabled style={{ minHeight: "36px" }}>
          {t("No options")}
        </MenuItem>
      ) : null}
    </Field>
  );
};
