import { Clipboard } from "@capacitor/clipboard";
import { AddressInput } from "../../../clients/graphqlTypes";
import { AddressDetails__AddressFragment } from "./Addresses.generated";
import { isString } from "lodash";

export const isAddressEqual = (
  a1: AddressDetails__AddressFragment | AddressInput | null,
  a2: AddressDetails__AddressFragment | AddressInput | null
) => {
  if (!a1 && !a2) return true;
  if (a1 && !a2) return false;
  if (!a1 && a2) return false;
  if (a1 && a2) {
    return (
      (a1.city ?? null) === (a2.city ?? null) &&
      (a1.countryCode ?? null) === (a2.countryCode ?? null) &&
      (a1.lat ?? null) === (a2.lat ?? null) &&
      (a1.lng ?? null) === (a2.lng ?? null) &&
      (a1.postalCode ?? null) === (a2.postalCode ?? null) &&
      (a1.streetLines1 ?? null) === (a2.streetLines1 ?? null)
    );
  }
  return false;
};

export const getAddressLabel = (
  address:
    | {
        countryCode: string;
        postalCode: string;
        city: string;
        streetLines1: string;
      }
    | null
    | undefined,
  separator = ", ",
  showCountry = false,
  title?: string,
  hideStreetLines = false
) => {
  if (!address) return null;
  return [
    (title && title !== address.streetLines1.trim()
      ? `${title.trim()}: `
      : "") + (hideStreetLines ? "" : address.streetLines1.trim()),
    `${address.postalCode} ${address.city}`.trim(),
    showCountry ? address.countryCode : undefined,
  ]
    .filter(Boolean)
    .join(separator)
    .trim();
};

/* prettier-ignore */
const GREY_STYLE = "style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e";

export const getAddressDirectionUrl = (
  address: Omit<AddressDetails__AddressFragment, "id"> | string
) => {
  // return `https://www.google.com/maps/dir/?api=1&origin=&destination=<address>`;
  /* prettier-ignore */
  return `https://www.google.com/maps/dir/?api=1&origin=&destination=${encodeURIComponent(isString(address) ? address : getAddressLabel(address) ?? '')}`;
};

export const getAddressSearchUrl = (
  address: Omit<AddressDetails__AddressFragment, "id"> | string
) => {
  // return `https://www.google.com/maps/search/?api=1&query=<address>`;
  /* prettier-ignore */
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(isString(address) ? address : getAddressLabel(address) ?? '')}`;
};

export const getAddressImageSrc = (
  address: Pick<AddressDetails__AddressFragment, "lat" | "lng">,
  width: number = 640,
  height: number = 320,
  zoom: number = 13,
  isGreyedOut: boolean = false,
  withMarker: boolean = false
) => {
  const parts = [
    `center=${address.lat},${address.lng}`,
    `zoom=${zoom}`,
    `size=${width}x${height}`,
    `maptype=roadmap`,
    `maptype=roadmap`,
    `travelmode=driving`,
    `key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`,
    withMarker
      ? `markers=color:red%7C${address.lat},${address.lng}`
      : undefined,
    isGreyedOut ? GREY_STYLE : undefined,
  ].filter(Boolean);
  return `https://maps.googleapis.com/maps/api/staticmap?${parts.join("&")}`;
};

const fallbackCopyTextToClipboard = (text: string): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const textArea = document.createElement("textarea");

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0";

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const result = document.execCommand("copy");
      /* prettier-ignore */
      console.log(`Fallback: Copying text command was ${result ? "successful" : "unsuccessful"}`);
      resolve(result);
    } catch (e) {
      console.error("Fallback: Oops, unable to copy", e);
      reject(e);
    } finally {
      document.body.removeChild(textArea);
    }
  });

export const copyTextToClipboard = (text: string): Promise<boolean> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return new Promise((resolve, reject) => {
    Clipboard.write({ string: text }).then(
      () => {
        console.log("Async: Copying to clipboard was successful!");
        resolve(true);
      },
      e => {
        console.error("Async: Could not copy text: ", e);
        reject(e);
      }
    );
    // navigator.clipboard.writeText(text).then(
    //   () => {
    //     console.log("Async: Copying to clipboard was successful!");
    //     resolve(true);
    //   },
    //   e => {
    //     console.error("Async: Could not copy text: ", e);
    //     reject(e);
    //   }
    // );
  });
};
