import { stripHtmlTags } from "@msys/common";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Attachment3dModel, AttachmentImage } from "./attachments/helpers";
import { GalleryModal, GalleryModalProps } from "./gallery/GalleryModal";
import { useScreenWidth } from "./hooks/useScreenWidth";
import {
  ModalOpenProcess,
  ModalOpenProcessRef,
} from "./modal/ModalOpenProcess";
import defaultImageUrl from "../assets/no-image.png";
import { ellipsisStyle } from "./text/Ellipsis";
import { TypographyWithMaxNumberOfLines } from "./text/Typography";

type Props<
  C extends React.ElementType,
  I extends AttachmentImage | Attachment3dModel
> = CardProps<C, { component?: C }> & {
  ActionButton?: React.ReactNode;
  StatusIcons?: React.ReactNode[];
  Tags?: React.ReactNode;
  title?: string;
  description?: string;
  article?: string;
  brand?: string;
  price?: string;
  imageUrl?: string;
  onClick?: () => void;
  size?: "small" | "medium";
  descriptionMaxNumberOfLines?: number;
  images?: GalleryModalProps<I>["images"];
};

export const MediaListItem = <
  C extends React.ElementType,
  I extends AttachmentImage | Attachment3dModel
>({
  ActionButton,
  StatusIcons,
  Tags,
  title,
  description,
  article,
  brand,
  price,
  imageUrl = defaultImageUrl,
  onClick,
  style,
  size: sizeProp,
  descriptionMaxNumberOfLines,
  images,
  ...props
}: Props<C, I>) => {
  const { isMinTablet } = useScreenWidth();
  const theme = useTheme();
  const size = sizeProp ?? (isMinTablet ? "medium" : "small");
  const processRef = React.useRef<ModalOpenProcessRef>();
  return (
    <Card
      {...props}
      sx={{
        display: "flex",
        position: "relative",
        minHeight: theme.layout.imageSize.sm,
        textDecoration: "none",
        flexShrink: 0,
        flexGrow: 0,
        ...props.sx,
      }}
      style={{
        minHeight:
          size !== "medium"
            ? theme.layout.imageSize.sm
            : theme.layout.imageSize.lg,
        ...(style ?? {}),
      }}
    >
      <CardMedia
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          alignSelf: "stretch",
          cursor: images && images.length > 0 ? "pointer" : "default",
          // aspectRatio: "1 / 1", // Not supported on Safari < 15 https://caniuse.com/mdn-css_properties_aspect-ratio
        }}
        image={imageUrl}
        style={{
          minHeight:
            size === "medium"
              ? theme.layout.imageSize.lg
              : theme.layout.imageSize.sm,
          paddingLeft:
            size !== "medium"
              ? theme.layout.imageSize.sm
              : theme.layout.imageSize.lg,
        }}
        onClick={
          images && images.length > 0
            ? e => {
                e.preventDefault();
                e.stopPropagation();
                processRef.current?.open();
              }
            : undefined
        }
      />
      <CardActionArea onClick={onClick} disableRipple sx={{ minWidth: 0 }}>
        <CardContent
          sx={{
            padding: 1,
            minHeight: "100%",
            minWidth: 0,
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
          style={{
            marginLeft: size === "medium" ? 4 : 0,
            marginRight: size === "medium" ? 4 : 0,
          }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack direction="column" spacing={0.25} style={ellipsisStyle}>
              {brand && (
                <Typography variant={"body2"} fontWeight="bold">
                  {brand}
                </Typography>
              )}
              <Typography variant={size === "medium" ? "h3" : "h4"} noWrap>
                {title}
              </Typography>
            </Stack>
            {ActionButton || (StatusIcons && StatusIcons.length > 0) ? (
              <Stack
                direction={"row"}
                spacing={0.5}
                alignItems="center"
                flexShrink={0}
                flexGrow={0}
                height={20}
                pt={0.5}
                onMouseDown={e => {
                  e.stopPropagation();
                }}
                onTouchStart={e => {
                  e.stopPropagation();
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {StatusIcons}
                {ActionButton}
              </Stack>
            ) : null}
          </Stack>

          <Stack
            direction={"row"}
            spacing={size === "medium" ? 2 : 1}
            justifyContent="space-between"
            flexGrow={1}
          >
            <Stack
              direction="column"
              flex={1}
              spacing={0.25}
              style={ellipsisStyle}
            >
              <TypographyWithMaxNumberOfLines
                $maxLines={
                  descriptionMaxNumberOfLines ?? (size === "medium" ? 2 : 1)
                }
                variant={"body2"}
              >
                {description ? stripHtmlTags(description, true) : null}
              </TypographyWithMaxNumberOfLines>
              {article && (
                <Typography variant={"body2"} sx={{ color: "grey.600" }} noWrap>
                  {article}
                </Typography>
              )}
              {Tags ? <Box>{Tags}</Box> : null}
            </Stack>

            {price ? (
              <Box alignSelf="flex-end" flexShrink={0} flexGrow={0}>
                <Typography variant={"body1"}>{price}</Typography>
              </Box>
            ) : null}
          </Stack>
        </CardContent>
      </CardActionArea>
      {images && (
        <ModalOpenProcess
          ref={processRef}
          Modal={GalleryModal}
          modalProps={{
            images,
          }}
        />
      )}
    </Card>
  );
};
