import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ButtonBase,
  Checkbox,
  Paper,
  Typography,
  Box,
  Theme,
} from "@mui/material";
import React from "react";
import { cssValue } from "../../../common/MuiThemeProvider";
import { AttachmentSnapshot } from "../../../clients/graphqlTypes";
import defaultImageUrl from "../../assets/images/no-image.png";

interface Props {
  isPreselected: boolean;
  isGreyedOut: boolean;
  label: string;
  image?: AttachmentSnapshot | null;
  multiple?: boolean;
  onClick(): void;
  withImage?: boolean;
}

export const PropertyOptionItem = ({
  isPreselected,
  isGreyedOut,
  onClick,
  label,
  image,
  multiple = false,
  withImage = false,
}: Props) => {
  return (
    <Paper
      disableRipple={true}
      component={ButtonBase}
      onClick={onClick}
      sx={theme => ({
        transition: "background-color 0.2s ease-out",
        borderRadius: "4px",
        width: "100%",
        minHeight: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...getButtonStyle({ theme, isPreselected, isGreyedOut }),
      })}
    >
      {withImage && (
        <Box
          flexShrink="0"
          flexGrow="0"
          width="100%"
          overflow="hidden"
          display="flex"
          position="relative"
          sx={{ aspectRatio: "4 / 3", borderRadius: "4px 4px 0 0" }}
        >
          <img
            draggable={false}
            src={image?.url ?? defaultImageUrl}
            alt={image?.title ?? ""}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
          {multiple ? (
            <Checkbox
              size="medium"
              checked={isPreselected}
              color="success"
              sx={theme => ({
                position: "absolute",
                top: "6px",
                left: "6px",
                padding: "2px",
                color: theme.palette.common.white,
                backgroundColor: "rgba(0,0,0,0.2)",
                transition:
                  "color 0.2s ease-out, background-color 0.2s ease-out",
                "&.Mui-checked": {
                  backgroundColor: "rgba(255,255,255,1)",
                },
              })}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            />
          ) : (
            <Checkbox
              size="medium"
              checked={isPreselected}
              color="success"
              icon={<CheckCircleIcon />}
              checkedIcon={<CheckCircleIcon />}
              sx={theme => ({
                ...(!isPreselected ? { display: "none" } : undefined),
                position: "absolute",
                top: "8px",
                left: "8px",
                padding: 0,
                "&.Mui-checked": {
                  backgroundColor: theme.palette.common.white,
                  "&.hover": {
                    backgroundColor: theme.palette.common.white,
                  },
                },
              })}
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            />
          )}
        </Box>
      )}
      <Box p={1}>
        <Typography variant="body2" fontWeight="bold">
          {label}
        </Typography>
      </Box>
    </Paper>
  );
};

const getButtonStyle = ({
  theme,
  isPreselected,
  isGreyedOut,
}: {
  theme: Theme;
  isPreselected: boolean;
  isGreyedOut: boolean;
}) => ({
  backgroundColor: isGreyedOut
    ? theme.palette.grey[100]
    : theme.palette.common.white,
  ...(!isPreselected
    ? {
        "&:hover:not(:has(button:hover)):not(:has(a:hover))": {
          backgroundColor: isGreyedOut
            ? theme.palette.grey[200]
            : theme.palette.grey[100],
        },
      }
    : undefined),
  ...(isPreselected
    ? {
        boxShadow: cssValue.preselectedOutlineShadow,
        borderColor: "transparent",
      }
    : undefined),
});
