import { CollapseSection, useScreenWidth } from "@msys/ui";
import { Box, Grid, Slider, Stack, Typography } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useField } from "formik";
import { groupBy } from "lodash";
import React from "react";
import { FormattedFloatOptionalField } from "../../commons/form-fields/FormattedFloatOptionalField";
import { SelectField } from "../../commons/form-fields/SelectField";
import { SelectMultipleField } from "../../commons/form-fields/SelectMultipleField";
import { TextField } from "../../commons/form-fields/TextField";
import { AttachmentSnapshot } from "../../../clients/graphqlTypes";
import { getPropertyDisplayedUnit } from "./properties";
import {
  Props2NonComputedAll_Props2Bool_Fragment,
  Props2NonComputedAll_Props2Number_Fragment,
  Props2NonComputedAll_Props2NumberArray_Fragment,
  Props2NonComputedAll_Props2Text_Fragment,
  Props2NonComputedAll_Props2TextArray_Fragment,
  Props2NonComputedAllFragment,
} from "./properties.generated";
import { PropertyOptionItem } from "./PropertyOptionItem";
import { usePropertyHelpers } from "./usePropertyHelpers";

interface Props {
  name: string;
  properties: Props2NonComputedAllFragment[];
}

export const PropertiesField = ({ name, properties }: Props) => {
  const { t } = useTranslate(["Global"]);

  const groups = React.useMemo(
    () =>
      properties.reduce(
        (acc, property, index) => {
          const group = acc.find(g => g.name === property.group);
          if (group) {
            // already exists
            group.properties.push({ property, index });
            return acc;
          }
          return [
            ...acc,
            {
              properties: [{ property, index }],
              name: property.group,
            },
          ];
        },
        [] as {
          name: string;
          properties: {
            property: Props2NonComputedAllFragment;
            index: number;
          }[];
        }[]
      ),
    [properties]
  );

  if (properties.length < 1) return null;

  return groups.length > 1 ? (
    <Stack direction="column" spacing={3}>
      {groups.map(group => (
        <CollapseSection
          key={`properties-group-${group.name ?? "__other__"}`}
          title={group.name ?? t("Other", { ns: "Global" })}
          titleVariant="h4"
          isInitiallyExpanded
        >
          <Stack direction="column" spacing={2} pt={1}>
            {group.properties.map(({ property, index }) => (
              <PropertyField
                key={property.key}
                name={name}
                property={property}
                index={index}
              />
            ))}
          </Stack>
        </CollapseSection>
      ))}
    </Stack>
  ) : (
    <Stack direction="column" spacing={3}>
      {properties.map((property, index) => (
        <PropertyField
          key={property.key}
          name={name}
          property={property}
          index={index}
        />
      ))}
    </Stack>
  );
};

const PropertyField = ({
  name,
  property,
  index,
}: {
  name: string;
  property: Props2NonComputedAllFragment;
  index: number;
}) => {
  switch (property.__typename) {
    case "Props2Bool":
      return (
        <PropertyBoolField
          key={property.key}
          name={name}
          index={index}
          property={property}
        />
      );
    case "Props2Text":
      if (property.allowedValuesText && property.allowedValuesText.length > 0) {
        return (
          <PropertyTextWithAllowedValuesField
            key={property.key}
            name={name}
            index={index}
            property={property}
          />
        );
      }
      return (
        <PropertyTextField
          key={property.key}
          name={name}
          index={index}
          property={property}
        />
      );
    case "Props2Number":
      if (
        property.allowedValuesNumber &&
        property.allowedValuesNumber.length > 0
      ) {
        return (
          <PropertyNumberWithAllowedValuesField
            key={property.key}
            name={name}
            index={index}
            property={property}
          />
        );
      }
      return (
        <PropertyNumberField
          key={property.key}
          name={name}
          index={index}
          property={property}
        />
      );
    case "Props2TextArray":
      if (property.allowedValuesText && property.allowedValuesText.length > 0) {
        return (
          <PropertyTextArrayWithAllowedValuesField
            key={property.key}
            name={name}
            index={index}
            property={property}
          />
        );
      }
      return null;
    case "Props2NumberArray":
      if (
        property.allowedValuesNumber &&
        property.allowedValuesNumber.length > 0
      ) {
        return (
          <PropertyNumberArrayWithAllowedValuesField
            key={property.key}
            name={name}
            index={index}
            property={property}
          />
        );
      }
      return null;
  }
};

const PropertyTextField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2Text_Fragment;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <TextField
        label={t("Enter the value", {
          ns: "Properties",
        })}
        type="text"
        name={`${name}[${index}].valueText`}
      />
    </Stack>
  );
};

const PropertyNumberField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2Number_Fragment;
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, { touched, error: formError }, { setValue: setFormValue }] =
    useField<number | null>(`${name}[${index}].valueNumber`);
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <Stack direction="row" spacing={1}>
        <FormattedFloatOptionalField
          label={t("Enter the value", {
            ns: "Properties",
          })}
          name={`${name}[${index}].valueNumber`}
          type="float"
          min={property.range?.min ?? undefined}
          max={property.range?.max ?? undefined}
          // unit={property.unit ? "\u00A0" + property.unit : undefined}
          sx={{ maxWidth: "150px", flexShrink: 0, flexGrow: 0 }}
        />
        {property.unit && (
          <Box display="flex" alignItems="center" height="48px">
            <Typography variant="body2" color="secondary">
              {getUnitLabel(property.unit)}
            </Typography>
          </Box>
        )}
      </Stack>
      {property.range?.min !== null &&
      property.range?.min !== undefined &&
      property.range?.max !== null &&
      property.range?.max !== undefined ? (
        <Stack direction="row" spacing={3} pt={1} alignItems="center">
          <Box>
            <Typography variant="body2">
              {getNumberLabel(property.range.min)}
            </Typography>
          </Box>
          <Slider
            color="secondary"
            value={value ?? property.range.min}
            onChange={(event, newValue) => {
              if (typeof newValue === "number") setFormValue(newValue);
            }}
            valueLabelDisplay="auto"
            min={property.range.min}
            max={property.range.max}
          />
          <Box>
            <Typography variant="body2">
              {getNumberLabel(property.range.max)}
            </Typography>
          </Box>
        </Stack>
      ) : null}
    </Stack>
  );
};

const PropertyBoolField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2Bool_Fragment;
}) => {
  const { getBoolLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      [true, false].map(value => ({
        value,
        label: getBoolLabel(value),
      })),
    [getBoolLabel]
  );
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <OptionsSelect<boolean>
        name={`${name}[${index}].valueBool`}
        options={options}
      />
    </Stack>
  );
};

const PropertyTextWithAllowedValuesField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2Text_Fragment;
}) => {
  const options = React.useMemo(
    () =>
      property.allowedValuesText!.map(({ allowedText, media }) => ({
        value: allowedText,
        label: allowedText,
        image: media ?? null,
      })),
    [property.allowedValuesText]
  );
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <OptionsSelect<string>
        name={`${name}[${index}].valueText`}
        options={options}
      />
    </Stack>
  );
};

const PropertyNumberWithAllowedValuesField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2Number_Fragment;
}) => {
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      property.allowedValuesNumber!.map(({ allowedNumber, media }) => ({
        value: allowedNumber,
        label: `${getNumberLabel(allowedNumber)}${getPropertyDisplayedUnit(
          property,
          getUnitLabel
        )}`,
        image: media ?? null,
      })),
    [property, getNumberLabel, getUnitLabel]
  );
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <OptionsSelect<number>
        name={`${name}[${index}].valueNumber`}
        options={options}
      />
    </Stack>
  );
};

const PropertyTextArrayWithAllowedValuesField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2TextArray_Fragment;
}) => {
  const options = React.useMemo(
    () =>
      property.allowedValuesText!.map(({ allowedText, media }) => ({
        value: allowedText,
        label: allowedText,
        image: media ?? null,
      })),
    [property.allowedValuesText]
  );
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <OptionsSelectMultiple<string>
        name={`${name}[${index}].valueTextArray`}
        options={options}
      />
    </Stack>
  );
};

const PropertyNumberArrayWithAllowedValuesField = ({
  name,
  index,
  property,
}: {
  name: string;
  index: number;
  property: Props2NonComputedAll_Props2NumberArray_Fragment;
}) => {
  const { getUnitLabel, getNumberLabel } = usePropertyHelpers();
  const options = React.useMemo(
    () =>
      property.allowedValuesNumber!.map(({ allowedNumber, media }) => ({
        value: allowedNumber,
        label: `${getNumberLabel(allowedNumber)}${getPropertyDisplayedUnit(
          property,
          getUnitLabel
        )}`,
        image: media ?? null,
      })),
    [property, getNumberLabel, getUnitLabel]
  );
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h3" color="secondary">
        {property.prompt || property.label}
      </Typography>
      <OptionsSelectMultiple<number>
        name={`${name}[${index}].valueNumberArray`}
        options={options}
      />
    </Stack>
  );
};

const OptionsSelect = <V,>({
  name,
  options,
}: {
  name: string;
  options: { value: V; label: string; image?: AttachmentSnapshot | null }[];
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, { touched, error: formError }, { setValue: setFormValue }] =
    useField<V | null>(name);
  const { isMinTablet } = useScreenWidth();
  const withImage = options.some(o => o.image);
  return options.length <= 6 || withImage ? (
    <Box>
      <Grid container spacing={1} columns={isMinTablet ? 3 : 2}>
        {options.map((option, index) => (
          <Grid item xs={1} key={index}>
            <PropertyOptionItem
              label={option.label}
              isPreselected={value === option.value}
              isGreyedOut={value !== option.value}
              image={option.image ?? null}
              withImage={withImage}
              onClick={() => {
                setFormValue(value === option.value ? null : option.value);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : (
    <SelectField
      name={name}
      options={options}
      label={t("Select the value", {
        ns: "Properties",
      })}
      clearable={true}
      onClear={() => {
        setFormValue(null);
      }}
    />
  );
};

const OptionsSelectMultiple = <V,>({
  name,
  options,
}: {
  name: string;
  options: { value: V; label: string; image?: AttachmentSnapshot | null }[];
}) => {
  const { t } = useTranslate(["Global", "Properties"]);
  const [{ value }, { touched, error: formError }, { setValue: setFormValue }] =
    useField<V[] | null>(name);
  const { isMinTablet } = useScreenWidth();
  const withImage = options.some(o => o.image);
  return options.length <= 6 || withImage ? (
    <Box>
      <Grid container spacing={1} columns={isMinTablet ? 3 : 2}>
        {options.map((option, index) => (
          <Grid item xs={1} key={index}>
            <PropertyOptionItem
              multiple
              label={option.label}
              isPreselected={value?.includes(option.value) ?? false}
              isGreyedOut={!value?.includes(option.value)}
              image={option.image ?? null}
              withImage={withImage}
              onClick={() => {
                setFormValue(
                  value?.includes(option.value)
                    ? value.filter(v => v !== option.value)
                    : [...(value ?? []), option.value]
                );
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : (
    <SelectMultipleField
      name={name}
      options={options}
      label={t("Select the value", {
        ns: "Properties",
      })}
    />
  );
};
