import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { assertNever, getDataOrNull } from "@msys/common";
import { reverse } from "lodash";
import { getUrlSearchParamsByParamsName } from "../../commons/hooks/useStateWithUrlParams";
import { BreadcrumbItem } from "../../commons/layout/PageBreadcrumbs";
import { PimProductPropertyInput } from "../../../clients/graphqlTypes";
import { productFiltersDefaultValue } from "./filters/ProductFilterFields";
import {
  ItemProductSearchFilterDefaultsFragment,
  ItemProductSearchFilterDefinitionsFragment,
} from "./productSearchFilters.generated";
import {
  GetProductFullDescription_ProductFragment,
  GetProductFullDescription_ProductSearchResultFragment,
  usePimAddSupplierProductViewedMutation,
  useProductBreadcrumbItems__ProductCategoryTreeQuery,
} from "./helper.generated";
import { PimProductPropertyFragment } from "./Product.generated";
import {
  usePimGetSupplierCatalogue__OrganisationWriteAccessQuery,
  usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery,
} from "./SupplierCatalogueAccess.generated";
import React, { useCallback, useEffect } from "react";

export function pimProductPropertyToInput(
  property: PimProductPropertyFragment
): PimProductPropertyInput {
  switch (property.__typename) {
    case "PimProductPropertyBool": {
      return {
        bool: {
          key: property.prop2.key,
          label: property.prop2.label,
          clientVisibility: property.prop2.clientVisibility,
          valueBool: property.prop2.valueBool,
        },
      };
    }
    case "PimProductPropertyNumber": {
      return {
        number: {
          key: property.prop2.key,
          label: property.prop2.label,
          clientVisibility: property.prop2.clientVisibility,
          valueNumber: property.prop2.valueNumber,
          unit: property.prop2.unit,
        },
      };
    }
    case "PimProductPropertyText": {
      return {
        text: {
          key: property.prop2.key,
          label: property.prop2.label,
          allowedValuesText: property.prop2.allowedValuesText.map(
            t => t.allowedText
          ),
          clientVisibility: property.prop2.clientVisibility,
          valueText: property.prop2.valueText,
        },
      };
    }
    case "PimProductPropertyRange": {
      return {
        range: {
          key: property.prop2.key,
          label: property.prop2.label,
          valueRange: property.pimProperty.valueRange,
          clientVisibility: property.prop2.clientVisibility,
          unit: property.prop2.unit,
        },
      };
    }
    case "PimProductPropertyBoolTyped": {
      return {
        boolTyped: {
          productTypeKey: property.pimProperty.productTypeKey,
          propertyTypeKey: property.pimProperty.propertyTypeKey,
          typeSystem: property.pimProperty.typeSystem,
          clientVisibility: property.prop2.clientVisibility,
          valueBool: property.prop2.valueBool,
        },
      };
    }
    case "PimProductPropertyNumberTyped": {
      return {
        numberTyped: {
          productTypeKey: property.pimProperty.productTypeKey,
          propertyTypeKey: property.pimProperty.propertyTypeKey,
          typeSystem: property.pimProperty.typeSystem,
          clientVisibility: property.prop2.clientVisibility,
          valueNumber: property.prop2.valueNumber,
          unit: property.pimProperty.unit,
        },
      };
    }
    case "PimProductPropertyTextTyped": {
      return {
        textTyped: {
          productTypeKey: property.pimProperty.productTypeKey,
          propertyTypeKey: property.pimProperty.propertyTypeKey,
          propertyTypeValueKey: property.prop2.valueText
            ? property.pimProperty.allowedPropertyTypeValues.find(
                v => v.label === property.prop2.valueText
              )?.key ?? null
            : null,
          typeSystem: property.pimProperty.typeSystem,
          clientVisibility: property.prop2.clientVisibility,
        },
      };
    }
    case "PimProductPropertyRangeTyped": {
      return {
        rangeTyped: {
          productTypeKey: property.pimProperty.productTypeKey,
          propertyTypeKey: property.pimProperty.propertyTypeKey,
          typeSystem: property.pimProperty.typeSystem,
          valueRange: property.pimProperty.valueRange,
          clientVisibility: property.prop2.clientVisibility,
          unit: property.pimProperty.unit,
        },
      };
    }
    default:
      throw assertNever(property);
  }
}

export function getProductFullDescription(
  product:
    | GetProductFullDescription_ProductFragment
    | GetProductFullDescription_ProductSearchResultFragment
) {
  const description = product.texts?.description?.trim();
  const extendedDescription = product.texts?.extendedDescription?.trim();
  return [
    description && !extendedDescription?.startsWith(description)
      ? description
      : null,
    extendedDescription,
  ]
    .filter(value => value)
    .join("\n");
}

export function definitionsToFormValues(
  definitions:
    | ItemProductSearchFilterDefaultsFragment
    | ItemProductSearchFilterDefinitionsFragment
) {
  return {
    organisationFavourite: definitions.favouritesFilter?.valueBool ?? null,
    productTypeIds: definitions.productTypeIdFilter
      ? [definitions.productTypeIdFilter.valueId]
      : null,
    supplierIds: definitions.supplierIdFilter
      ? definitions.supplierIdFilter.__typename === "EntitySearchIdFilter"
        ? [definitions.supplierIdFilter.valueId]
        : definitions.supplierIdFilter.valueIdIn
      : null,
    brands: definitions.brandFilter
      ? definitions.brandFilter.__typename === "EntitySearchTextFilter"
        ? [definitions.brandFilter.valueText]
        : definitions.brandFilter.valueTextIn
      : null,
    brandLines: definitions.brandLineFilter
      ? definitions.brandLineFilter.__typename === "EntitySearchTextFilter"
        ? [definitions.brandLineFilter.valueText]
        : definitions.brandLineFilter.valueTextIn
      : null,
    listPriceMax: definitions.listPriceFilter
      ? definitions.listPriceFilter.__typename === "EntitySearchNumberFilter" &&
        (definitions.listPriceFilter.operatorNumber === "lt" ||
          definitions.listPriceFilter.operatorNumber === "lte" ||
          definitions.listPriceFilter.operatorNumber === "eq")
        ? definitions.listPriceFilter.valueNumber
        : definitions.listPriceFilter.__typename ===
            "EntitySearchNumberBetweenFilter"
          ? definitions.listPriceFilter.valueNumberBetween.max
          : null
      : null,
    listPriceMin: definitions.listPriceFilter
      ? definitions.listPriceFilter.__typename === "EntitySearchNumberFilter" &&
        (definitions.listPriceFilter.operatorNumber === "gt" ||
          definitions.listPriceFilter.operatorNumber === "gte" ||
          definitions.listPriceFilter.operatorNumber === "eq")
        ? definitions.listPriceFilter.valueNumber
        : definitions.listPriceFilter.__typename ===
            "EntitySearchNumberBetweenFilter"
          ? definitions.listPriceFilter.valueNumberBetween.min
          : null
      : null,
    netPriceMin: null,
    netPriceMax: null,

    propertiesBool: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterBoolFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorBool,
                value: propertyFilter.valueBool,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterBoolInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorBoolIn,
                  value: propertyFilter.valueBoolIn,
                }
              : []
        )
      : null,
    propertiesNumber: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename ===
          "EntitySearchPropertyFilterNumberBetweenFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorNumberBetween,
                value: propertyFilter.valueNumberBetween,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterNumberInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorNumberIn,
                  value: propertyFilter.valueNumberIn,
                }
              : propertyFilter.__typename ===
                  "EntitySearchPropertyFilterNumberFilter"
                ? {
                    key: propertyFilter.key,
                    operator: propertyFilter.operatorNumber,
                    value: propertyFilter.valueNumber,
                  }
                : []
        )
      : null,
    propertiesText: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterTextInFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorTextIn,
                value: propertyFilter.valueTextIn,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterTextFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorText,
                  value: propertyFilter.valueText,
                }
              : []
        )
      : null,
    supplierProductCategoryKey: null,
  };
}

export function useCanCreateSupplierCatalogueProduct() {
  const client = useApolloClient();
  const query = usePimSearchSupplierCatalogues__OrganisationWriteAccessQuery({
    client,
    fetchPolicy: "cache-and-network",
  });

  const supplierCatalogues = getDataOrNull(
    query.data?.pimSearchSupplierCatalogues
  )?.supplierCatalogues;

  return {
    canCreateSupplierCatalogueProduct: Boolean(
      supplierCatalogues && supplierCatalogues.length > 0
    ),
    loading: query.loading,
  };
}

export function useCanEditSupplierCatalogueProduct(
  supplierId: string,
  supplierCatalogueId: string
) {
  const client = useApolloClient();
  const query = usePimGetSupplierCatalogue__OrganisationWriteAccessQuery({
    client,
    variables: { supplierId, supplierCatalogueId },
    fetchPolicy: "cache-and-network",
  });

  const supplierCatalogue = getDataOrNull(
    query.data?.pimGetSupplierCatalogue
  )?.supplierCatalogue;

  return {
    canEditSupplierCatalogueProduct:
      supplierCatalogue &&
      supplierCatalogue.supplierCatalogueAccess.organisationCanWrite,
    loading: query.loading,
  };
}

export function useProductViewed({ client }: { client: ApolloClient<object> }) {
  const [pimAddSupplierProductViewedMutation] =
    usePimAddSupplierProductViewedMutation({ client });

  const addProductViewed = useCallback(
    async (product: { articleNumber: string; supplierId: string }) => {
      await pimAddSupplierProductViewedMutation({
        variables: {
          input: {
            articleNumber: product.articleNumber,
            supplierId: product.supplierId,
          },
        },
      });
    },
    [pimAddSupplierProductViewedMutation]
  );

  return {
    addProductViewed,
  };
}

export function useProductViewedEffect({
  client,
  product: { articleNumber, supplierId },
}: {
  client: ApolloClient<object>;
  product: { articleNumber: string; supplierId: string };
}) {
  const { addProductViewed } = useProductViewed({ client });

  useEffect(() => {
    addProductViewed({ articleNumber, supplierId });
  }, [articleNumber, supplierId, addProductViewed]);
}

export function useProductBreadcrumbs(
  supplierId: string | null,
  categoryKey: string | null,
  productTitle: string
) {
  const client = useApolloClient();

  const query = useProductBreadcrumbItems__ProductCategoryTreeQuery({
    client,
    variables: { supplierId: supplierId!, categoryKey: categoryKey! },
    skip: !supplierId || !categoryKey,
  });

  const productParentCategories =
    getDataOrNull(
      (query.data ?? query.previousData)?.pimGetSupplierProductCategoryTree
    )?.tree?.parents ?? [];
  const productCategory = getDataOrNull(
    (query.data ?? query.previousData)?.pimGetSupplierProductCategoryTree
  )?.tree?.productCategory;

  const breadcrumbItems = React.useMemo((): BreadcrumbItem[] => {
    const items: BreadcrumbItem[] = (
      productCategory
        ? [...reverse([...productParentCategories]), productCategory]
        : []
    ).map(category => ({
      title: category.title,
      to: {
        pathname: `/products/catalogues`,
        search: getUrlSearchParamsByParamsName(
          {
            ...productFiltersDefaultValue,
            supplierProductCategoryKey: category.key,
            supplierIds: [supplierId],
          },
          "filters"
        ).toString(),
      },
    }));
    if (items.length > 0) {
      items.push({ title: productTitle, to: "/" });
    }
    return items;
  }, [productCategory, productParentCategories, supplierId, productTitle]);

  return breadcrumbItems;
}
