import { Box } from "@mui/material";
import React from "react";
import { useWindowSize } from "react-use";
import { useScreenWidth } from "../hooks/useScreenWidth";
import { ModalDialog } from "./Modal";

interface Props
  extends Pick<
    React.ComponentProps<typeof ModalDialog>,
    | "id"
    | "dialogProps"
    | "fixedHeight"
    | "notInStack"
    | "handleClose"
    | "alwaysVisible"
  > {}

export const ModalWithSidePanelDialog = ({
  id,
  dialogProps,
  alwaysVisible = false,
  notInStack = false,
  fixedHeight = false,
  handleClose,
  children,
}: React.PropsWithChildren<Props>) => {
  const { isMinDesktop } = useScreenWidth();

  return (
    <ModalDialog
      id={id}
      maxWidth={isMinDesktop ? false : undefined}
      dialogProps={{
        ...dialogProps,
        PaperProps: {
          ...dialogProps?.PaperProps,
          sx: {
            ...dialogProps?.PaperProps?.sx,
            width:
              isMinDesktop && !dialogProps?.fullScreen
                ? "fit-content"
                : undefined,
          },
        },
      }}
      alwaysVisible={alwaysVisible}
      notInStack={notInStack}
      fixedHeight={fixedHeight}
      handleClose={handleClose}
    >
      {children}
    </ModalDialog>
  );
};

type MaxWidth = "xs" | "sm" | "md" | "lg" | "xl";
const DIALOG_WIDTHS: Record<MaxWidth, number> = {
  xs: 442,
  sm: 598,
  md: 982,
  lg: 1198,
  xl: 1534,
};

export const ModalWithSidePanelSections = ({
  maxWidth = "sm",
  maxWidthWithSideContent = "xl",
  sideContent,
  children,
}: React.PropsWithChildren<{
  maxWidth?: MaxWidth;
  maxWidthWithSideContent?: MaxWidth;
  sideContent: React.ReactNode | undefined | null;
}>) => {
  const { width: windowWidth } = useWindowSize();
  const { isMinDesktop } = useScreenWidth();
  const canShowSidePanel = isMinDesktop;

  if (!canShowSidePanel) {
    return (
      <Box display="flex" flexDirection={"column"} height="100%">
        {children}
        {sideContent}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexWrap={"nowrap"}
      flexDirection="row"
      flex={1}
      height="100%"
      width={
        sideContent
          ? `min(${DIALOG_WIDTHS[maxWidthWithSideContent]}px, calc(${windowWidth}px - 66px))`
          : `min(${DIALOG_WIDTHS[maxWidth]}px, calc(${windowWidth}px - 66px))`
      }
      sx={{
        transition: "width 0.3s ease-out, height 0.3s ease-out",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        flex={0}
        minWidth={`min(${DIALOG_WIDTHS[maxWidth]}px, 100%)`}
        sx={{ overflowY: "auto" }}
      >
        {children}
      </Box>
      {sideContent && (
        <Box
          display="flex"
          flexDirection="column"
          flex={0}
          minWidth={`calc(min(${DIALOG_WIDTHS[maxWidthWithSideContent]}px, calc(${windowWidth}px - 66px)) - ${DIALOG_WIDTHS[maxWidth]}px)`}
        >
          {sideContent}
        </Box>
      )}
    </Box>
  );
};
